import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

  ngOnInit(): void {}

  /**
   * FUNCTIONALITIES
   */

  goToHome() {
    this.staticUtilitiesSE.goTo('/home', slideNavigationTypes.slideToBottom);
  }
}

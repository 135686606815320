<div class="footerGeneralContainer">
  <div class="footer">
    <img class="logo" src="/assets/Images/Logos/LogoFennech_Horizontal.png" alt="" (click)="goToHome()">
    <div class="data">
      <div class="top">
        <a href="https://www.google.es/maps/place/Lanzadera/@39.4622222,-0.3306305,17z/data=!3m2!4b1!5s0xd60485cd0699da7:0xcf810d6533333ca0!4m6!3m5!1s0xd60488f372defa3:0xcafa5cd672a8178!8m2!3d39.4622222!4d-0.3280556!16s%2Fg%2F11b6nvyn8t?entry=ttu "
          target="_blank">LANZADERA</a>
        <div class="separator"></div>
        <a href="mailto:info@fennech.io">info@fennech.io</a>
        <div class="separator"></div>
        <a href="tel:+34697393162">+34 697 393 162</a>
      </div>
      <div class="bottom">
        <a href="https://fennech.io/documents/Aviso_Legal_Web_fennech.pdf" target="_blank">Aviso legal</a>
        <a href="https://fennech.io/documents/Politica_Privacidad_WEB_fennech.pdf" target="_blank">Politica de
          privacidad</a>
      </div>
    </div>
  </div>
</div>
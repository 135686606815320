<div class="thirdBlockGeneralContainer">
  <h3>
    De intuición a conocimiento: impulsa tu empresa con nosotros.
  </h3>
  <p>
    Cotizar proyectos de manera estática ya no es suficiente. Aprendemos del pasado y ajustamos el futuro con tecnología
    de aprendizaje automático.
  </p>
  <div class="priceContainer">
    <p class="price">Desde <b>3'50€ </b>/ usuario</p>
    <div class="efect"></div>
    <div class="efect"></div>
  </div>

  <btn class="action" (click)="goToContact()">
    <ng-container>
      <span>Solicitar una demo</span>
    </ng-container>
  </btn>
</div>
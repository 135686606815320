<div class="secondBlockGeneralContainer">
  <img src="/assets/Images/secondBlockImage.png" alt="">
  <div class="bullets">
    <div class="data">
      <div class="container">
        <h2>Gestion de proyectos</h2>
        <span><mat-icon>check</mat-icon>Cotiza tus proyectos</span>
        <span><mat-icon>check</mat-icon>Planea las fases de tu proyecto</span>
        <span><mat-icon>check</mat-icon>Actualiza diariamente tu productividad</span>
        <span><mat-icon>check</mat-icon>Registra las incidencias</span>
      </div>
      <div class="container">
        <h2>Gestion de equipos</h2>
        <span><mat-icon>check</mat-icon>Planifica tus procesos - Weekly</span>
        <span><mat-icon>check</mat-icon>Planifica tus procesos - Daily</span>
        <span><mat-icon>check</mat-icon>Observa el estado de tu equipo</span>
      </div>
    </div>
    <div class="data">
      <div class="container">
        <h2>CRM</h2>
        <span><mat-icon>check</mat-icon>Crea tu base de datos de Clientes</span>
        <span><mat-icon>check</mat-icon>Registra oportunidades de tu negocio</span>
      </div>
      <div class="container">
        <h2>Finanzas</h2>
        <span><mat-icon>check</mat-icon>Prevee los cobros de tus proyectos</span>
        <span><mat-icon>check</mat-icon>Registra los datos de tu empresa</span>
        <span><mat-icon>check</mat-icon>Centraliza tus facturas</span>
      </div>
    </div>
  </div>
</div>
import { Component, HostListener, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.scrollY != 0) {
      this.changeBackgroundActive = true;
    } else {
      this.changeBackgroundActive = false;
    }
  }

  changeBackgroundActive: boolean = false;
  constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

  ngOnInit(): void {}

  /**
   * FUNCTIONALITIES
   */

  goToHome() {
    this.staticUtilitiesSE.goTo('/home', slideNavigationTypes.slideToBottom);
  }
  goToFennech() {
    window.open('https://platform.fennech.io', '_blank');
  }
  goToContact() {
    (document.getElementById('contactForm') as HTMLElement).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuasarLibraryModule } from '@quasar-dynamics/basic-designsystem';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FennechLoaderComponent } from './Shared/Components/fennech-loader/fennech-loader.component';
import { HomeComponent } from './Pages/home/home.component';
import { HeaderComponent } from './Shared/Layout/header/header.component';
import { PageLayoutComponent } from './Shared/Layout/page-layout/page-layout.component';
import { HomeFirstBlockComponent } from './Pages/home/_holders/home-first-block/home-first-block.component';
import { ScrollTopButtonComponent } from './Shared/Components/scroll-top-button/scroll-top-button.component';
import { HomeThirdBlockComponent } from './Pages/home/_holders/home-third-block/home-third-block.component';
import { FooterComponent } from './Shared/Layout/footer/footer.component';
import { HomeFourthBlockComponent } from './Pages/Home/_holders/home-fourth-block/home-fourth-block.component';
import { HomeSecondBlockComponent } from './Pages/Home/_holders/home-second-block/home-second-block.component';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    AppComponent,
    FennechLoaderComponent,
    HomeComponent,
    HeaderComponent,
    PageLayoutComponent,
    HomeFirstBlockComponent,
    ScrollTopButtonComponent,
    HomeThirdBlockComponent,
    FooterComponent,
    HomeFourthBlockComponent,
    HomeSecondBlockComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    QuasarLibraryModule,
    BrowserAnimationsModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

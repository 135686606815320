import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'scrollTopButton',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.scss'],
})
export class ScrollTopButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

import { transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AnimationControllerService,
  VariablesDesignSystemService,
  slideNavigationFunctions,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition(
        '* => slideToLeft',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
      ),
      transition(
        '* => slideToRight',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
      ),
      transition(
        '* => slideToTop',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
      ),
      transition(
        '* => slideToBottom',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom
        )
      ),
      transition(
        '* => slideToLeftDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
      ),
      transition(
        '* => slideToRightDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
      ),
      transition(
        '* => slideToTopDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
      ),
      transition(
        '* => slideToBottomDuplicate',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom
        )
      ),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'fennech';
  constructor(
    private router: Router,
    private titleSE: Title,
    public animService: AnimationControllerService
  ) {}
  ngOnInit(): void {
    VariablesDesignSystemService.setIsRelease(environment.production);
    VariablesDesignSystemService.setUrlApi(environment.urlApi);
    this.routerHandler();
  }

  /**
   * FUNCTIONALITIES
   */
  routerHandler() {
    this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleSE.setTitle(`fennech - ${title}`);
        }
      });
  }
}

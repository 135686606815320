<div class="firstBlockGeneralContainer">
  <h1>
    Automatiza la recolección de tu data para llevar tu productividad más allá.
  </h1>
  <h2>
    El futuro de la gestión empresarial está aquí: descubre cómo nuestro SaaS puede transformar la forma en que operas
    tu empresa.
  </h2>
  <btn class="action" (click)="goToContact()">
    <ng-container>
      <span>Solicitar una demo</span>
    </ng-container>
  </btn>
  <img src="/assets/Images/imageDashboard.png" alt="">
</div>
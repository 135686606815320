import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'homeThirdBlock',
  templateUrl: './home-third-block.component.html',
  styleUrls: ['./home-third-block.component.scss'],
})
export class HomeThirdBlockComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  goToContact() {
    (document.getElementById('contactForm') as HTMLElement).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'homeFirstBlock',
  templateUrl: './home-first-block.component.html',
  styleUrls: ['./home-first-block.component.scss'],
})
export class HomeFirstBlockComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  goToContact() {
    (document.getElementById('contactForm') as HTMLElement).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}

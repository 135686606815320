<pageLayout>
  <ng-container>
    <div class="homeGeneralContainer">
      <homeFirstBlock></homeFirstBlock>
      <homeSecondBlock></homeSecondBlock>
      <homeThirdBlock></homeThirdBlock>
      <homeFourthBlock></homeFourthBlock>
    </div>

  </ng-container>
</pageLayout>
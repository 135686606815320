import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  iOptionsCustomInput,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { FennechLoaderComponent } from 'src/app/Shared/Components/fennech-loader/fennech-loader.component';

@Component({
  selector: 'homeFourthBlock',
  templateUrl: './home-fourth-block.component.html',
  styleUrls: ['./home-fourth-block.component.scss'],
})
export class HomeFourthBlockComponent implements OnInit {
  isCompleted: boolean = false;
  formObj: any = {
    name: '',
    email: '',
    phone: '',
    company: '',
    position: '',
    checked: false,
  };
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Nombre completo',
    classList: 'specialFormInput',
    required: true,
  };

  emailOptions: iOptionsCustomInput = {
    label: 'Correo electrónico',
    type: 'text',
    placeholder: 'Correo electrónico de contacto',
    classList: 'specialFormInput',
    required: true,
  };

  phoneOptions: iOptionsCustomInput = {
    label: 'Número de teléfono',
    type: 'text',
    placeholder: 'Número de teléfono',
    classList: 'specialFormInput',
    required: true,
  };

  companyOptions: iOptionsCustomInput = {
    label: 'Empresa',
    type: 'text',
    placeholder: 'Nombre de la empresa',
    classList: 'specialFormInput',
    required: true,
  };

  positionOptions: iOptionsCustomInput = {
    label: 'Cargo/Posición',
    type: 'text',
    placeholder: 'Tu cargo o posición en la empresa',
    classList: 'specialFormInput',
    required: true,
  };

  constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

  ngOnInit(): void {}

  /**
   * HANDLERS
   */
  checkIfDataIsCompletedHandler() {
    this.isCompleted =
      StaticUtilitiesService.isCompleteObject(this.formObj) &&
      StaticUtilitiesService.isValidFormat(this.formObj.email);
  }
  checkIfIsValidFormat() {
    if (!StaticUtilitiesService.isValidFormat(this.formObj.email)) {
      StaticUtilitiesService.showError(
        'Hay errores en los campos',
        'El correo electrónico no tiene un formato válido'
      );
    }
  }

  /**
   * CALLS TO API
   */
  sendData() {
    this.staticUtilitiesSE.openLoader(FennechLoaderComponent);
    setTimeout(() => {
      this.staticUtilitiesSE.closeLoader();
      StaticUtilitiesService.showFeedback(
        'Se ha enviado tu solicitud correctamente',
        'En breve nuestros expertos contactaran contigo'
      );
    }, 1000);
  }
}

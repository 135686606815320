<div class="fourthBlockGeneralContainer" id="contactForm">

  <div class="container">
    <div class="left">
      <h3>Contacta con nosotros.</h3>
      <img src="/assets/Images/Logos/logoFennechAnimado.gif" alt="">
    </div>

    <div class="right">
      <customInput [options]="nameOptions" [(ngModel)]="formObj.name" (change)="checkIfDataIsCompletedHandler()">
      </customInput>
      <customInput [options]="emailOptions" [(ngModel)]="formObj.email"
        (change)="checkIfDataIsCompletedHandler(); checkIfIsValidFormat()">
      </customInput>
      <customInput [options]="phoneOptions" [(ngModel)]="formObj.phone" (change)="checkIfDataIsCompletedHandler()">
      </customInput>
      <customInput [options]="companyOptions" [(ngModel)]="formObj.company" (change)="checkIfDataIsCompletedHandler()">
      </customInput>
      <customInput [options]="positionOptions" [(ngModel)]="formObj.position"
        (change)="checkIfDataIsCompletedHandler()"></customInput>
      <div class="checkboxContainer">
        <mat-checkbox [(ngModel)]="formObj.checked" (change)="checkIfDataIsCompletedHandler()"></mat-checkbox>
        <span>He leido y acepto la <a href="https://fennech.io/documents/Politica_Privacidad_WEB_fennech.pdf"
            target="_blank">politica
            de privacidad</a> de fennech.</span>
      </div>
      <btn class="action" [ngClass]="{'disabled': !this.isCompleted}" (click)="sendData()">
        <ng-container>
          <span>Enviar</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>
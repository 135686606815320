<div class="headerGeneralContainer" [ngClass]="{'scrollAnimation': changeBackgroundActive}">
  <div class="header">
    <img class="logo" src="/assets/Images/Logos/LogoFennech_Horizontal.png" alt="" (click)="goToHome()">
    <div class="buttonContainer">
      <btn class="secondary" (click)="goToContact()">
        <ng-container>
          <span>Solicitar una demo</span>
        </ng-container>
      </btn>
      <btn class="action" (click)="goToFennech()">
        <ng-container>
          <span>Acceder</span>
          <mat-icon class="material-icons-outlined">
            logout
          </mat-icon>
        </ng-container>
      </btn>
    </div>

  </div>
</div>